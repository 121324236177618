<template>
 <div class="s_login_admin g-flex-align-center g-flex-justify-center">
   <div class="s-login-admin-container g-flex-column g-flex-align-center">
    <h3 class="s-login-admin-title">{{formData.title}}</h3>
    <div class="s-login-admin-form">
      <el-form ref="form" :model="form" :rules="rules" class="s-form g-flex-column g-flex-align-start">
          <el-form-item prop="user_name">
            <div class="s-login-admin-form-item">
              <el-input type="text" placeholder="请输入账号" v-model="form.user_name" autocomplete="off" prefix-icon="iconfont iconuser"/>
            </div>
          </el-form-item>
          <el-form-item prop="password">
            <div class="s-login-admin-form-item">
              <el-input :type="isPwdType ? 'password': ''"  placeholder="请输入密码" v-model="form.password" @keyup.enter.native="login" autocomplete="off">
                  <i slot="prefix" class="iconfont iconmima"></i>
                  <i slot="suffix" @click="toggleType" class="iconfont" :class="isPwdType ? 'iconyanjing-bukejian-copy' : 'iconmimakejianguanbi'"></i>
              </el-input>
            </div>
          </el-form-item>

          <div class="s-login-admin-form-btn g-flex-justify-center">
            <el-button type="primary" @click="login">登录</el-button>
          </div>
        </el-form>
    </div>
   </div>
 </div>
</template>

<script>
import { apiLogin } from '@/utils/api.js'
export default {
  props: {
    formData: {
      type: Object,
      default() {
        return {
          type: 0,  //1 为后台管理系统  2为商家管理系统
          logo: '/img/logo2.png',
          title: '',
        }
      }
    }
  },
  data() {
    return {
      isPwdType: true,
      form: {
          user_name: '',
          password: ''
      },
      // 表单验证，需要在 el-form-item 元素中增加 prop 属性
      rules: {
          user_name: [
              { required: true, message: '账号不可为空', trigger: 'blur' }
          ],
          password: [
              { required: true, message: '密码不可为空', trigger: 'blur' }
          ]
      }
    }
  },
  methods: {
    toggleType() {
        this.isPwdType = !this.isPwdType
    },
    login() {
      // 表单绑定验证功能
      this.$refs['form'].validate(async (valid) => {
          if (valid) {
              const loading = this.$loading()
              const { success, data } = await apiLogin(this.form).catch(()=> { loading.close() })
              loading.close()
              if(!success) return 
              this.$global.setToken(data.token)
              this.$ws.setToken(data.token)
              this.$router.replace('/')
          } else {
              return false;
          }
      })
    },
  }
}
</script>

<style lang='scss'>
.s_login_admin {
  width: 100%;
  height: 100%;
  background: url('/img/login_admin.png') no-repeat;
  background-position: center;
  background-size: cover;
  .s-login-admin-container {
    .s-login-admin-title {
      font-size: 46px;
      color: $white;
      font-weight: normal;
    }
    .s-login-admin-form {
      background: $white;
      padding: 70px 120px;
      border-radius: 10px;
      .iconfont {
        font-size: 24px;
        cursor: pointer;
      }
      .el-form {
        .el-form-item {
          .s-login-admin-form-item {
            border-bottom: 1px solid #D1D1D1;
            width: 300px;
          }
          .el-form-item__content {
            .el-input {
              .el-input__inner {
                border: none;
              }
            }
          }
        }

        .s-login-admin-form-btn {
          width: 100%;
          margin-top: 20px;
          .el-button {
            padding: 10px 120px;
            font-size: 16px;
            letter-spacing: 2px;
            background: linear-gradient(133deg, #6CB4FF, #409EFF);
            border-radius: 20px;
            &:hover {
              background: #409EFF;
            }
          }
        }
      }
    }
  }

}
</style>