<template>
 <div class="c_concat_us_pop">
   <ul class="c-concat-us-pop-list g-flex-column g-flex-align-center">
     <li class="c-concat-us-pop-item g-flex-column g-flex-align-center g-flex-justify-center" @mouseout="hoverOut" @mouseover="hoverOver(0)">
       <i class="iconfont iconqq"></i>
       <!-- QQ客服 -->
       <div class="c-concat-us-pop-item-qq" v-show="hoverIndex == 0"> 
         <div class="c-concat-us-arrow"></div>
         <p class="c-concat-us-pop-item-qq-title">QQ客服</p>
         <div class="c-concat-us-pop-item-qq-title-time">工作日：早上8:00-晚上24:00 </div>
         <ul class="c-concat-us-pop-item-qq-list">
           <li class="c-concat-us-pop-item-qq-list-item g-flex-align-center">
             <p class="c-concat-us-pop-item-qq-list-item-title">客服001:</p>
             <span class="c-concat-us-pop-item-qq-list-item-value">{{kefuInfo.qq}}</span>
             <div class="s-main-store-left-top-list-item-btn" @click="copyClick(kefuInfo.qq)">复制</div>
           </li>
          <!-- <li class="c-concat-us-pop-item-qq-list-item g-flex-align-center">
             <p class="c-concat-us-pop-item-qq-list-item-title">客服001:</p>
             <span class="c-concat-us-pop-item-qq-list-item-value">1095084052</span>
             <div class="s-main-store-left-top-list-item-btn" @click="copyClick('1095084052')">复制</div>
           </li> -->
         </ul>
       </div>
     </li>
     <li class="c-concat-us-pop-item g-flex-column g-flex-align-center g-flex-justify-center" @mouseout="hoverOut" @mouseover="hoverOver(1)">
       <i class="iconfont icondianhua"></i>
       <!-- 联系电话 -->
       <div class="c-concat-us-pop-item-qq"  v-show="hoverIndex == 1">
         <div class="c-concat-us-arrow"></div>
         <p class="c-concat-us-pop-item-qq-title">联系电话</p>
         <div class="c-concat-us-pop-item-qq-title-time">工作日：早上8:00-晚上24:00 </div>
         <ul class="c-concat-us-pop-item-qq-list">
          <li class="c-concat-us-pop-item-qq-list-item g-flex-align-center">
             <span class="c-concat-us-pop-item-qq-list-item-value">{{kefuInfo.mobile}}</span>
             <div class="s-main-store-left-top-list-item-btn" @click="copyClick(kefuInfo.mobile)">复制</div>
           </li>
         </ul>
       </div>
     </li>
     <li class="c-concat-us-pop-item g-flex-column g-flex-align-center g-flex-justify-center" @mouseout="hoverOut" @mouseover="hoverOver(2)">
       <i class="iconfont iconico"></i>
       <!-- 微信客服 -->
        <div class="c-concat-us-pop-item-qq" v-show="hoverIndex == 2">
         <div class="c-concat-us-arrow"></div>
         <p class="c-concat-us-pop-item-qq-title">微信客服</p>
         <div class="c-concat-us-pop-item-qq-title-time">上班时间：早上8:00-晚上24:00 </div>
         <ul class="c-concat-us-pop-item-qq-list">
           <li class="c-concat-us-pop-item-qq-list-item g-flex-align-center g-flex-justify-center">
             <el-image style="width: 180px; height: 180px" :src="kefuInfo.wechat_img"></el-image>
           </li>
         </ul>
       </div>
     </li>
   </ul>
 </div>
</template>

<script>
export default {
  props: {
    kefuInfo: {
      type: Object,
      default() {
        return {
          mobile: '',
          qq: '',
          wechat: '',
          wechat_img: ''
        }
      }
    }
  },
  data() {
    return {
      concatList: [
        { title:'', icon: 'iconqq' },
        { title:'', icon: 'icondianhua' },
        { title:'', icon: 'iconico' },
      ],
      hoverIndex: -1,
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ]
    }
  },
  methods: {
    // 复制
    copyClick(message) {
      this.$copyText(message).then(() => {
        this.$message.success({
          type: 'success',
          message: '复制成功'
        });
      },  () => {
        this.$message.error('复制失败,请重试');
      })
    },
    hoverOver(index) {
      this.hoverIndex = index
    },
    hoverOut() {
      this.hoverIndex = -1
    }
  }
}
</script>

<style lang='scss'>
.c_concat_us_pop {
  background: #ffffff;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  z-index: 99;
  height: 280px;
  width: 60px;
  box-shadow: 0px 6px 20px 6px rgba(194, 197, 251, 0.5);
  border-radius: 10px;
  .c-concat-us-pop-list {
    padding: 0 12px;
    height: 100%; 
    .c-concat-us-pop-item {
      flex: 1;
      cursor: pointer;
      width: 100%;
      border-bottom: 2px solid #EEEEEE;
      position: relative;
      .iconfont {
        color: #3B84FE;
        font-size: 32px;
      }
      &:nth-last-of-type(1) {
        border-bottom: none;
      }
      .c-concat-us-pop-item-qq {
        width: 280px;
        position: absolute;
        background: #FFFFFF;
        right: 100%;
        border-radius: 15px;
        box-shadow: 2px 2px 5px 5px rgba(192, 192, 192, 0.4);
        padding: 20px;
        .c-concat-us-pop-item-qq-title {
          font-size: 16px;
          color: #409EFF;
        }
        .c-concat-us-pop-item-qq-title-time {
          font-size: 14px;
          color: #409EFF;
          padding: 6px 0;
        }
        .c-concat-us-pop-item-qq-list {
          .c-concat-us-pop-item-qq-list-item {
            padding-top: 10px;
            color: #409EFF;
            font-size: 16px;
            .c-concat-us-pop-item-qq-list-item-title {
              padding-right: 6px;
            }
            .c-concat-us-pop-item-qq-list-item-value {
              flex: 1;
            } 
           .s-main-store-left-top-list-item-btn {
              user-select: none;
              font-size: 14px;
              margin-left: 10px;
              padding: 5px 10px;
              background: $white;
              cursor: pointer;
              border: 1px solid $blue;
              border-radius: 5px;
            }
          }
        }
      }
      .c-concat-us-arrow {
          position: absolute;
          top: 50%;
          right: -6px;
          transform: translateY(-50%);
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 6px solid #FFFFFF;
      }
    } 
  }

}
</style>