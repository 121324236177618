<template>
    <div class="s_login_business g-flex-column g-flex-align-center g-flex-justify-center">
        <div class="s-login-logo g-flex-column g-flex-align-center">
            <img :src="formData.logo" alt="">
            <p>{{formData.title}}</p>
        </div>
        <div class="s-form-box g-flex-align-center g-flex-justify-center">
            <img :src="imgUrl" alt="">
            <el-form ref="form" :model="form" :rules="rules" class="s-form g-flex-column g-flex-align-start">
                <h3 class="s-login-title">欢迎登录</h3>
                <p class="s-login-tip">如果您有任何疑问，请随时与我们联系，给予你最便捷的生活态度</p>
                <el-form-item prop="mobile">
                    <el-input type="text" placeholder="请输入账号" v-model="form.mobile" autocomplete="off" prefix-icon="iconfont iconuser"/>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input :type="isPwdType ? 'password': ''"  placeholder="请输入密码" v-model="form.password" @keyup.enter.native="login" autocomplete="off">
                         <i slot="prefix" class="iconfont iconmima"></i>
                         <i slot="suffix" @click="toggleType" class="iconfont" :class="isPwdType ? 'iconbukejian' : 'iconmimakejianguanbi'"></i>
                    </el-input>
                </el-form-item>
                <el-button type="primary" @click="login">登录</el-button>
                <p class="s-login-item-reg g-flex-justify-center g-flex-align-center">还没有账号？ <span @click="$router.replace({ name: 'register'})">立即注册</span></p>
            </el-form>
        </div>
        
        <concat-us-pop ref="ConcatUsPop"  v-if="$global.adminUser.type == 2" :kefuInfo="formData.kefu"/>
    </div>
</template>

<script>
// if(!(/^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试'); 
    import { apiLogin, apiGetConfig } from '@/utils/api.js'
    import ConcatUsPop from '@/components/ConcatUsPop.vue'
    export default {
        components: { ConcatUsPop },
        props: {
          formData: {
            type: Object,
            default() {
              return {
                type: 0,  //1 为后台管理系统  2为商家管理系统\
                logo: '/img/logo2.png',
                title: '',
                kefu: {}
              }
            }
          }
        },
        name: 'Login',
        data() {
            return {
                imgUrl: require('../../assets/img/login_form_img.png'),
                
                isPwdType: true,
                
                form: {
                    mobile: '',
                    password: ''
                },

                // 表单验证，需要在 el-form-item 元素中增加 prop 属性
                rules: {
                    mobile: [
                        {required: true, message: '手机号不可为空', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '密码不可为空', trigger: 'blur'}
                    ]
                }
            }
        },
        created() {
        },
        methods: {
            async apiGetConfigHandel() {
                const { success, data } = await apiGetConfig()
                if(!success) return
                this.$global.config.web_title = data.data.web_title
            },    

            toggleType() {
                this.isPwdType = !this.isPwdType
            },

            login() {
                // 表单绑定验证功能
                this.$refs['form'].validate(async (valid) => {
                    if (valid) {
                        // 是手机就是手机mobile 不然就是user_name
                        // 3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9]|9[0-7]
                        if(!(/^1\d{10}$/.test(this.form.mobile))) return this.$message.error('请输入正确的手机号')
                        const loading = this.$loading()
                        const { success, data } = await apiLogin(this.form).catch(()=> { loading.close() })
                        loading.close()
                        if(!success) return 
                        this.$global.setToken(data.token)
                        this.$ws.setToken(data.token)
                        this.$router.replace('/')
                    } else {
                        return false;
                    }
                })
            },
        }
    }
</script>

<style lang="scss">
    .s_login_business {
        background: url("/img/login_bg.jpg") no-repeat;
        background-position: center;
        background-size: cover;
        width:100%;
        height:100%;
        .s-login-logo {
            padding-bottom: 20px;
            p {
                padding-top: 14px;
                font-size: 30px;
                color: #007AFF;
            }
            img {
                height: 100px;
                vertical-align: middle;
                object-fit: contain;
            }
        }
        .s-form-box {
            background-color: rgba($color: #fff, $alpha: 0.7);
            border: 1px solid #DCDFE6;
            width: 960px;
            height: 550px;
            border-radius: 20px;

            img {
                width: 346px;
                height: 254px;
                object-fit: cover;
                vertical-align: middle;
            }
            
            .s-form {
                padding-left: 100px;
                .el-button {
                    background: #007AFF;
                    width: 100%;
                }
                .el-form-item {
                    width: 100%;
                    .el-input {
                        color: #B5B5B5;
                        .el-input__inner {
                            border: 1px solid #7cbbff;
                            border-radius: 8px;
                            padding-left: 40px;
                            padding-right: 40px;
                        }
                        .el-input__prefix {
                            i {
                                font-size: 26px;
                            }
                        }
                        .el-input__suffix {
                            i {
                                font-size: 26px;
                                cursor: pointer;
                            }
                        }
                    }
                }
                .s-login-title {
                    font-size: 28px;
                    text-align: center;
                    font-weight: normal;
                    color: #007AFF;
                    margin: 0;
                    padding: 0;
                    padding-bottom: 20px;
                }
                .s-login-tip {
                    font-size: 12px;
                    color: #B5B5B5;
                    padding-bottom: 28px;
                }

                .s-login-item-reg {
                    padding-top: 40px;
                    width: 100%;
                    font-size: 14px;
                    color: #999;
                    span {
                        color: #007AFF;
                        padding: 0 4px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>