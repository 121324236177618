<template>
  <div class="v_login g-flex-column g-flex-align-center g-flex-justify-center">
      
    <!-- 后台登录 -->
    <login-admin  v-if="form.type == 1" :formData="form"/>

    <!-- 商家登录 -->
    <login-bussiness v-if="form.type == 2" :formData="form"/>
  </div>
</template>

<script>
// if(!(/^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试');
import { apiGetDesktop } from "@/utils/api.js";
import LoginBussiness from "./LoginBussiness.vue";
import LoginAdmin from "./LoginAdmin.vue";
export default {
  name: "Login",
  components: { LoginBussiness, LoginAdmin },
  data() {
    return {
        form: {
            type: 0,  //1 为后台管理系统  2为商家管理系统\
            logo: '/img/logo2.png',
            title: '',
        }
    };
  },
  created() {
    this.apiGetDesktopHandel()
  },
  methods: {
    //  获取登录界面
    async apiGetDesktopHandel() {
        let loading = this.$loading({
            text: '拼命加载中',
            background: 'rgba(0, 0, 0, 0.4)'
        })
        const { success, data } = await apiGetDesktop().catch(() => {  loading.close() })
        loading.close()
        if (!success) return;
        this.form = data.data
        this.$global.adminUser.type = data.data.type
        this.$global.config.kefu = data.data.kefu
        document.title = data.data.title
    },
  },
};
</script>

<style lang="scss">
.v_login {
  width: 100%;
  height: 100%;
}
</style>
